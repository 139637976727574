import { combineReducers } from 'redux';
import alert from './alert';
import auth from './auth';
import pokloni from './pokloni';
import kakoPoruciti from './kakoPoruciti';
import opsirnije from './opsirnije';
import izmeni from './izmeni';
import newsletter from './newsletter';

export default combineReducers({
  alert,
  auth,
  pokloni,
  kakoPoruciti,
  opsirnije,
  izmeni,
  newsletter
});
