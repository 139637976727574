import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { newsletterSingUp } from '../../actions/newsletter';
import SpinnerSmall from './SpinnerSmall';

const Newsletter = ({ newsletterSingUp, loading }) => {
  const [formData, setFormData] = useState({
    email: '',
    ime: '',
    prezime: '',
  });

  const { email, ime, prezime } = formData;

  useEffect(() => {
    if (!loading) {
      setFormData({
        email: '',
        ime: '',
        prezime: '',
      });
    }
  }, [loading]);

  const onChange = async (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    newsletterSingUp(ime, prezime, email);
    e.preventDefault();
  };

  return (
    <Fragment>
      <section id='newsletter' className='bg-info text-dark pt-3'>
        {loading && (
          <div className='newsletter-overlay'>
            <SpinnerSmall />
          </div>
        )}
        <div className='container'>
          <div className='row'>
            <div className='col text-center mb-1'>
              <h4>PRIJAVI SE ZA NEWSLETTER</h4>
            </div>
          </div>
          <form onSubmit={(e) => onSubmit(e)}>
            <div className='row'>
              <div className='col-md-3 mb-3'>
                <input
                  type='text'
                  className='form-control mb-resp'
                  placeholder='Ime'
                  name='ime'
                  value={ime}
                  onChange={(e) => onChange(e)}
                />
              </div>
              <div className='col-md-3 mb-3'>
                <input
                  type='text'
                  className='form-control mb-resp'
                  placeholder='Prezime'
                  name='prezime'
                  value={prezime}
                  onChange={(e) => onChange(e)}
                />
              </div>
              <div className='col-md-3 mb-3'>
                <input
                  type='email'
                  className='form-control mb-resp'
                  placeholder='Email*'
                  name='email'
                  value={email}
                  required
                  onChange={(e) => onChange(e)}
                />
              </div>
              <div className='col-md-3 mb-3'>
                <input
                  type='submit'
                  className='btn btn-outline-primary btn-block'
                  value='Prijavi se'
                />
              </div>
            </div>
          </form>
        </div>
      </section>
    </Fragment>
  );
};

Newsletter.propTypes = {
  newsletterSingUp: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.newsletter.loading,
});

export default connect(mapStateToProps, { newsletterSingUp })(Newsletter);
