import {
  IZMENI_LOADING,
  IZMENI_LOADING_DONE,
  IZMENI_REQUEST,
  IZMENI_FINISHED,
  IZMENI_SUCCESS,
  IZMENI_ERROR
} from '../actions/types';

const initialState = {
  poklon: {},
  izmeniLoading: true,
  izmeniRequest: false,
  izmeniFinished: false,
  izmeniSuccess: false
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case IZMENI_LOADING:
      return {
        ...state,
        izmeniLoading: true,
        izmeniRequest: false,
        izmeniFinished: false,
        izmeniSuccess: false
      };
    case IZMENI_LOADING_DONE:
      return {
        ...state,
        poklon: payload,
        izmeniLoading: false
      };
    case IZMENI_REQUEST:
      return {
        ...state,
        izmeniRequest: true,
        izmeniFinished: false,
        izmeniSuccess: false
      };
    case IZMENI_FINISHED:
      return {
        ...state,
        izmeniRequest: false,
        izmeniFinished: true
      };
    case IZMENI_SUCCESS:
      return {
        ...state,
        izmeniSuccess: true
      };
    case IZMENI_ERROR:
      return {
        ...state,
        poklon: {},
        izmeniLoading: false,
        izmeniRequest: false,
        izmeniFinished: false,
        izmeniSuccess: false
      };

    default:
      return state;
  }
}
