import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { removeAlert } from '../../actions/alert';

const Alert = ({ alerts, removeAlert }) =>
  alerts !== null &&
  alerts.length > 0 &&
  alerts.map(alert => (
    <Fragment key={alert.id}>
      <div className='alert-container  px-4'>
        <div className={`container alert alert-${alert.alertType} p-4 `}>
          <div>
            <h4>
              <i className='fas fa-exclamation-circle fa-x2'></i> {alert.msg}
            </h4>
          </div>
          <div>
            <button className='btn' onClick={e => removeAlert(alert.id)}>
              <i className='fas fa-times'></i>
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  ));

Alert.propTypes = {
  alerts: PropTypes.array.isRequired,
  removeAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  alerts: state.alert
});

export default connect(mapStateToProps, { removeAlert })(Alert);
