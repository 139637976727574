import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getSinglePoklon } from '../../actions/izmeni';
import PropTypes from 'prop-types';
import Spinner from '../layout/Spinner';
import IzmeniForm from '../utils/IzmeniForm';

const Izmeni = ({
  getSinglePoklon,
  match,
  izmeni: {
    poklon,
    izmeniLoading,
    izmeniRequest,
    izmeniFinished,
    izmeniSuccess
  }
}) => {
  const id = match.params.id;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getSinglePoklon(id);
  }, [getSinglePoklon, id]);

  return izmeniLoading ? (
    <Spinner />
  ) : (
    <section id='izmeni-poklon'>
      <div className='container'>
        <div className='row my-5 py-5'>
          <IzmeniForm />
        </div>
      </div>
    </section>
  );
};

Izmeni.propTypes = {
  getSinglePoklon: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  izmeni: state.izmeni
});

export default connect(mapStateToProps, { getSinglePoklon })(Izmeni);
