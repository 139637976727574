import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updatePoklon } from '../../actions/izmeni';
import SpinnerSmall from '../layout/SpinnerSmall';
import { Redirect } from 'react-router-dom';

const IzmeniForm = ({
  updatePoklon,
  izmeni: {
    poklon,
    izmeniLoading,
    izmeniRequest,
    izmeniFinished,
    izmeniSuccess,
  },
}) => {
  const [formData, setFormData] = useState({
    naziv: poklon.naziv,
    sifra: poklon.sifra,
    cena: poklon.cena,
    dimenzija: poklon.dimenzija,
    materijal: poklon.materijal,
    opis: poklon.opis,
    kategorija: poklon.kategorija,
    glavnaSlika: '',
    slike: '',
    dostupnost: poklon.dostupnost,
  });

  // Redirect
  const [redirect, setRedirect] = useState(false);

  const [brojSlika, setBrojSlika] = useState({
    brojGlavnih: 'Glavna slika',
    brojOstalih: 'Ostale slike',
  });

  const { brojGlavnih, brojOstalih } = brojSlika;

  const { naziv, sifra, cena, dimenzija, materijal, kategorija, opis } =
    formData;

  useEffect(() => {
    if (izmeniSuccess) {
      setRedirect(true);
    }
  }, [izmeniSuccess]);

  // Redirect if update success
  if (redirect) {
    return <Redirect to={`/poklon/${poklon._id}`} />;
  }

  const onChangeGlavna = (e) => {
    setFormData({
      ...formData,
      glavnaSlika: e.target.files[0],
    });
    setBrojSlika({
      ...brojSlika,
      brojGlavnih: 'Broj selektovanih: 1',
    });
  };

  const onChangeOstale = (e) => {
    setFormData({
      ...formData,
      slike: e.target.files,
    });
    setBrojSlika({
      ...brojSlika,
      brojOstalih: `Broj selektovanih: ${e.target.files.length}`,
    });
  };

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeRadio = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: JSON.parse(e.target.value),
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    updatePoklon(poklon._id, formData);
  };

  return (
    <div className='col' id='izmeni-form'>
      {izmeniRequest && (
        <div className='izmeni-overlay'>
          <SpinnerSmall />
        </div>
      )}

      <form
        className='card card-shadow py-4 px-5 text-dark'
        onSubmit={onSubmit}
      >
        <div className='row mb-1'>
          <div className='col text-center'>
            <h1>
              IZMENI POKLON <i className='far fa-edit ml-4'></i>
            </h1>
          </div>
        </div>
        <div className='row  '>
          <div className='col mb-4'>
            <label htmlFor='naziv'>Naziv</label>
            <input
              type='text'
              className='form-control'
              id='naziv'
              name='naziv'
              placeholder='Unesi naziv poklona'
              value={naziv}
              onChange={onChange}
            />
          </div>
        </div>
        <div className='row '>
          <div className='col-lg-6 mb-4'>
            <label htmlFor='sifra'>Šifra</label>
            <input
              type='text'
              className='form-control'
              id='sifra'
              name='sifra'
              placeholder='Unesi šifru poklona'
              value={sifra}
              onChange={onChange}
            />
          </div>
          <div className='col-lg-6 mb-4'>
            <label htmlFor='cena'>Cena</label>
            <input
              type='text'
              className='form-control'
              id='cena'
              name='cena'
              placeholder='Unesi cenu poklona'
              value={cena}
              onChange={onChange}
            />
          </div>
          <div className='col-lg-6 mb-4'>
            <label htmlFor='dimenzija'>Dimenzija</label>
            <input
              type='text'
              className='form-control'
              id='dimenzija'
              name='dimenzija'
              placeholder='Unesi dimenziju poklona'
              value={dimenzija}
              onChange={onChange}
            />
          </div>
          <div className='col-lg-6 mb-4'>
            <label htmlFor='materijal'>Materijal</label>
            <input
              type='text'
              className='form-control'
              id='materijal'
              name='materijal'
              placeholder='Unesi materijal poklona'
              value={materijal}
              onChange={onChange}
            />
          </div>
        </div>
        <div className='row '>
          <div className='col-lg-4 mb-4'>
            <label htmlFor='kategorija'>Izaberi kategoriju</label>
            <select
              className='form-control'
              id='kategorija'
              name='kategorija'
              value={kategorija}
              onChange={onChange}
            >
              <option defaultValue value=''>
                --
              </option>
              <option value='za-nju-ili-njega'>Za nju ili njega</option>
              <option value='za-vaspitacice-i-uciteljice'>
                Za vaspitačice i učiteljice
              </option>
              <option value='magneti-za-svadbe'>Magneti za svadbe</option>
              <option value='magneti-za-rodjendane'>
                Magneti za rođendane
              </option>
              <option value='repro-materijal'>Repro materijal</option>
              <option value='pozivnice'>Pozivnice</option>
              <option value='pokloni-za-sve'>Pokloni za sve</option>
              <option value='korporativni-pokloni'>Korporativni pokloni</option>
              <option value='ostalo'>Ostalo</option>
            </select>
          </div>
          <div className='col-lg-4 mb-4'>
            <label htmlFor='glavnaSlika'>Uploaduj glavnu sliku</label>
            <div className='custom-file'>
              <input
                type='file'
                className='custom-file-input'
                id='glavnaSlika'
                name='glavnaSlika'
                onChange={onChangeGlavna}
              />
              <label className='custom-file-label' htmlFor='glavnaSlika'>
                {brojGlavnih}
              </label>
            </div>
          </div>
          <div className='col-lg-4 mb-4'>
            <label htmlFor='slike'>Uploaduj ostale slike</label>
            <div className='custom-file'>
              <input
                type='file'
                className='custom-file-input'
                id='slike'
                name='slike'
                multiple
                onChange={onChangeOstale}
              />
              <label className='custom-file-label' htmlFor='slike'>
                {brojOstalih}
              </label>
            </div>
          </div>
        </div>
        <div className='row '>
          <div className='col d-flex mb-4'>
            <p className='mb-0 mr-4'>Dostupnost:</p>
            <div className='form-check form-check-inline mr-4'>
              <input
                className='form-check-input'
                type='radio'
                name='dostupnost'
                id='da'
                value='true'
                defaultChecked={poklon.dostupnost ? true : false}
                onChange={onChangeRadio}
              />
              <label className='form-check-label' htmlFor='da'>
                Da
              </label>
            </div>
            <div className='form-check form-check-inline'>
              <input
                className='form-check-input'
                type='radio'
                name='dostupnost'
                id='ne'
                value='false'
                defaultChecked={poklon.dostupnost ? false : true}
                onChange={onChangeRadio}
              />
              <label className='form-check-label' htmlFor='ne'>
                Ne
              </label>
            </div>
          </div>
        </div>
        <div className='row '>
          <div className='col mb-4'>
            <label htmlFor='opis'>Opis:</label>
            <textarea
              className='form-control'
              id='opis'
              name='opis'
              rows='3'
              placeholder='Kratak opis poklona...'
              value={opis}
              onChange={onChange}
            ></textarea>
          </div>
        </div>
        <div className='row'>
          <div className='col text-right'>
            <input
              type='submit'
              value='Izmeni'
              className='btn btn-lg btn-dark px-4'
            />
          </div>
        </div>
      </form>
    </div>
  );
};

IzmeniForm.propTypes = {
  updatePoklon: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  izmeni: state.izmeni,
});

export default connect(mapStateToProps, { updatePoklon })(IzmeniForm);
