import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import { useHistory } from 'react-router-dom';
import Poklon from './Poklon';
import { getCurrentPoklon, getSimilar } from '../../actions/opsirnije';
import Spinner from '../layout/Spinner';
import SlicniProizvod from './SlicniProizvod';

const Opsirnije = ({
  match,
  getCurrentPoklon,
  getSimilar,
  opsirnije: { currentPoklon, similar, currentLoading, similarLoading }
}) => {
  const id = match.params.id;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    getCurrentPoklon(id);
  }, [id, getCurrentPoklon]);

  useEffect(() => {
    if (!currentLoading) {
      getSimilar(currentPoklon.kategorija);
    }
    // getSimilar(currentPoklon.kategorija);
  }, [getSimilar, currentPoklon.kategorija, currentLoading]);

  return currentLoading || similarLoading ? (
    <Fragment>
      <Spinner />
    </Fragment>
  ) : (
    <Fragment>
      {/* Opsirnije */}
      <section id='opsirnije'>
        <div className='container'>
          <div className='row mt-5 pt-3'>
            <div className='col'>
              <h3>
                <Link
                  to={`/pokloni/${currentPoklon.kategorija}`}
                  className='nav-link'
                  style={{ display: 'contents' }}
                >
                  <i className='fas fa-arrow-left'></i> Nazad
                </Link>
              </h3>
            </div>
          </div>
          {/* Poklon */}
          <div className='row mt-3 mb-5 pb-5'>
            <div className='col'>
              <Poklon key={currentPoklon._id} poklon={currentPoklon} />
            </div>
          </div>
        </div>
      </section>

      {similar && !similarLoading && (
        <section id='slicni-proizvodi' className='mb-5 pb-5'>
          <div className='container'>
            <div className='row'>
              <div className='col text-dark'>
                <h5>Slični proizvodi:</h5>
              </div>
            </div>
            <div className='row'>
              {similar.length > 1 ? (
                similar
                  .filter(poklon => poklon._id !== currentPoklon._id)
                  .slice(0, 6)
                  .map(poklon => (
                    <SlicniProizvod key={poklon._id} poklon={poklon} />
                  ))
              ) : !similarLoading &&
                similar[0] &&
                similar[0]._id !== currentPoklon._id ? (
                <SlicniProizvod key={similar[0]._id} poklon={similar[0]} />
              ) : (
                <div className='col'>
                  <p className='text-gray'>
                    Trenutno nemamo sličnih proizvoda...
                  </p>
                </div>
              )}
            </div>
          </div>
        </section>
      )}
    </Fragment>
  );
};

Opsirnije.propTypes = {
  getCurrentPoklon: PropTypes.func.isRequired,
  getSimilar: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  opsirnije: state.opsirnije
});

export default connect(mapStateToProps, {
  getCurrentPoklon,
  getSimilar
})(Opsirnije);
