import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const PoklonCard = ({ poklon }) => {
  const { sifra, cena, glavnaSlika, _id } = poklon;

  return (
    <div className='col-xl-4 col-lg-6 col-md-6 col-6 p-2'>
      <div className='card card-animate card-shadow'>
        <Link to={`/poklon/${_id}`}>
          <img
            className='card-img-top'
            src={`/uploads/${glavnaSlika}`}
            alt='Card  cap'
          />
        </Link>
        <div className='card-body text-center'>
          <div className='row'>
            <div className='col text-left text-dark'>
              <h4 className='mb-3'>
                Cena od: <span className='text-gray'>{cena} din</span>
              </h4>
              <h4>
                Šifra: <span className='text-gray'>{sifra}</span>
              </h4>
            </div>
          </div>
          <div className='row'>
            <div className='col d-flex justify-content-end align-items-end'>
              <Link
                to={`/poklon/${_id}`}
                className='btn btn-outline-dark btn-lg btn-block mt-2'
              >
                Opširnije
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

PoklonCard.propTypes = {
  poklon: PropTypes.object.isRequired,
};

export default PoklonCard;
