import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import ObrazacZaIdentifikaciju from '../../img/obrazac-za-identifikaciju.png';

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <Fragment>
      <section id='footer' className='bg-dark text-info pt-5'>
        <div className='container'>
          <div className='row mb-4'>
            <div className='col-md-6'>
              <h4 className='mb-3'>Kontaktirajte nas:</h4>
              <a href='tel:+381612251225' className='not-link'>
                <i className='fas fa-phone-square-alt mr-2'></i> Telefon:
                061/225-1-225
              </a>
              <a href='tel:+381612251225' className='not-link'>
                <i className='fab fa-viber mr-2'></i> Viber: 061/225-1-225
              </a>
              <a href='tel:+381612251225' className='not-link'>
                <i className='fab fa-whatsapp mr-2'></i> Whatsapp: 061/225-1-225
              </a>
              <a href='mailto:reddot.ar@gmail.com' className='not-link'>
                <i className='far fa-envelope mr-2'></i> Mail:
                reddot.ar@gmail.com
              </a>
            </div>
            <div className='col-md-6 text-right footer-col-resp'>
              <h4 className='mb-3'>Obrazac za identifikaciju:</h4>
              <p>
                <Link
                  to={ObrazacZaIdentifikaciju}
                  className='not-link'
                  target='_blank'
                  download
                >
                  Download <i className='fas fa-download ml-2'></i>
                </Link>
              </p>
            </div>
          </div>
          <div className='row'>
            <div className='col copyright'>
              <p className='text-center'>
                &copy; {year} - Red Dot - Sva prava zadržana
              </p>
              <p className='text-center'>
                Sve fotografije na ovom sajtu su autorske tj pripadaju Red
                Dot-u, nije dozvoljena ponovna upotreba istih bez pismene
                saglasnosti
              </p>
              <p className='text-center opacity-5'>
                Developed by{' '}
                <a
                  className='not-link display-inline'
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://radovanovic.net/'
                >
                  radovanovic.net
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Footer;
