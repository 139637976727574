import {
  SET_CURRENT_POKLON,
  OPSIRNIJE_ERROR,
  SET_SIMILAR,
  OPSIRNIJE_LOADING,
  SIMILAR_LOADING
} from '../actions/types';

const initialState = {
  currentPoklon: {},
  similar: [],
  currentLoading: true,
  similarLoading: true
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_CURRENT_POKLON:
      return {
        ...state,
        currentPoklon: payload,
        currentLoading: false
      };
    case SET_SIMILAR:
      return {
        ...state,
        similar: payload,
        similarLoading: false
      };
    case OPSIRNIJE_ERROR:
      return {
        ...state,
        currentPoklon: {},
        currentLoading: true,
        similarLoading: true
      };
    case OPSIRNIJE_LOADING:
      return {
        ...state,
        currentLoading: true
      };
    case SIMILAR_LOADING:
      return {
        ...state,
        similarLoading: true
      };
    default:
      return state;
  }
}
