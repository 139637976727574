import { NEWSLETTER_REQUEST, NEWSLETTER_FINISHED } from '../actions/types';

const initialState = {
  loading: false
};

export default function(state = initialState, action) {
  const { type } = action;

  switch (type) {
    case NEWSLETTER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case NEWSLETTER_FINISHED:
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
}
