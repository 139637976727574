import {
  SET_SIFRA,
  FORM_SUCCESS,
  ORDER_LOADING,
  ORDER_FINISHED
} from '../actions/types';

const initialState = {
  sifra: '',
  formSuccess: false,
  orderLoading: false
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_SIFRA:
      return {
        ...state,
        sifra: payload
      };
    case FORM_SUCCESS:
      return {
        ...state,
        formSuccess: true
      };
    case ORDER_LOADING:
      return {
        ...state,
        formSuccess: false,
        orderLoading: true
      };
    case ORDER_FINISHED:
      return {
        ...state,
        orderLoading: false
      };
    default:
      return state;
  }
}
