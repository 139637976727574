import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Carousel from 'react-bootstrap/Carousel';

const CarouselBasic = ({
  glavnaSlika,
  slike,
  controls,
  indicators,
  interval
}) => {
  return (
    <Fragment>
      <Carousel controls={controls} indicators={indicators} interval={interval}>
        <Carousel.Item>
          <img
            className='d-block w-100'
            src={`/uploads/${glavnaSlika}`}
            alt='First slide'
          />
        </Carousel.Item>

        {slike.length > 1 ? (
          slike.map((slika, index) => (
            <Carousel.Item key={index}>
              <img
                className='d-block w-100'
                src={`/uploads/${slika}`}
                alt={slika}
              />
            </Carousel.Item>
          ))
        ) : (
          <Carousel.Item>
            <img
              className='d-block w-100'
              src={`/uploads/${slike[0]}`}
              alt='slide'
            />
          </Carousel.Item>
        )}
      </Carousel>
    </Fragment>
  );
};

CarouselBasic.propTypes = {
  glavnaSlika: PropTypes.string.isRequired,
  slike: PropTypes.array.isRequired,
  controls: PropTypes.bool.isRequired,
  indicators: PropTypes.bool.isRequired
};

export default CarouselBasic;
