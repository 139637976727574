import React, { Fragment, useState, useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { Navbar } from 'react-bootstrap'
import logo from '../../img/red-dot-logo.svg'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { logout } from '../../actions/auth'
import { setFilters } from '../../actions/pokloni'
// import Pdf from '../../img/promo2022.pdf'

const MainNavbarRev01 = ({
  auth: { isAuthenticated, loading },
  logout,
  setFilters,
}) => {
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const onLogout = () => {
    setExpanded(false)
    logout()
  }

  const onClick = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
    const filterObject = { page: '1' }
    setFilters(filterObject)
    setExpanded(false)
  }

  const authLinks = (
    <ul className='navbar-nav ml-auto'>
      <li className='nav-item'>
        <NavLink
          exact
          to='/'
          className='nav-link pocetna'
          activeClassName='active'
          onClick={onClick}
        >
          Početna
        </NavLink>
      </li>
      <li className='nav-item dropdown'>
        <div className='nav-link dropdown-toggle' data-toggle='dropdown'>
          Pokloni
        </div>
        <div className='dropdown-menu animate slideIn pb-3'>
          <NavLink
            exact
            to='/pokloni/za-nju-ili-njega'
            className='nav-link ml-4'
            activeClassName='active'
            onClick={onClick}
          >
            Za nju ili njega
          </NavLink>
          <NavLink
            exact
            to='/pokloni/pokloni-za-sve'
            className='nav-link ml-4'
            activeClassName='active'
            onClick={onClick}
          >
            Pokloni za sve
          </NavLink>
          <NavLink
            exact
            to='/pokloni/za-vaspitacice-i-uciteljice'
            className='nav-link ml-4'
            activeClassName='active'
            onClick={onClick}
          >
            Za vaspitačice i učiteljice
          </NavLink>
          <NavLink
            exact
            to='/pokloni/magneti-za-rodjendane'
            className='nav-link ml-4'
            activeClassName='active'
            onClick={onClick}
          >
            Magneti za rođendane
          </NavLink>
          <NavLink
            exact
            to='/pokloni/magneti-za-svadbe'
            className='nav-link ml-4'
            activeClassName='active'
            onClick={onClick}
          >
            Magneti za svadbe
          </NavLink>
          <NavLink
            exact
            to='/pokloni/pozivnice'
            className='nav-link ml-4'
            activeClassName='active'
            onClick={onClick}
          >
            Pozivnice
          </NavLink>

          <NavLink
            exact
            to='/pokloni/repro-materijal'
            className='nav-link ml-4'
            activeClassName='active'
            onClick={onClick}
          >
            Repro materijal
          </NavLink>

          <NavLink
            exact
            to='/pokloni/ostalo'
            className='nav-link ml-4'
            activeClassName='active'
            onClick={onClick}
          >
            Ostalo
          </NavLink>
        </div>
      </li>
      <li className='nav-item dropdown'>
        <div className='nav-link dropdown-toggle' data-toggle='dropdown'>
          Promo
        </div>
        <div className='dropdown-menu animate slideIn pb-3'>
          <a
            href='https://online.fliphtml5.com/zjgjy/cecv/'
            target='_blank'
            className='nav-link ml-4'
          >
            Promotivni Katalog
          </a>
        </div>
      </li>
      <li className='nav-item'>
        <NavLink
          exact
          to='/pokloni/korporativni-pokloni'
          className='nav-link'
          activeClassName='active'
          onClick={onClick}
        >
          Korporativni Pokloni
        </NavLink>
      </li>
      <li className='nav-item'>
        <NavLink
          exact
          to='/kako-poruciti'
          className='nav-link'
          activeClassName='active'
          onClick={onClick}
        >
          Kako Poručiti
        </NavLink>
      </li>
      <li className='nav-item d-flex flex-direction-column'>
        <span>
          <a
            href='https://www.facebook.com/reciseci/'
            target='_blank'
            rel='noopener noreferrer'
            className='nav-link mr-2'
            onClick={onClick}
          >
            <i className='fab fa-facebook-square'></i>
          </a>
        </span>
        <span>
          <a
            href='https://www.instagram.com/reci_seci/?hl=en'
            target='_blank'
            rel='noopener noreferrer'
            className='nav-link'
            onClick={onClick}
          >
            <i className='fab fa-instagram'></i>
          </a>
        </span>
      </li>

      {/* Admin Dropdown */}
      <li className='nav-item dropdown'>
        <div className='nav-link dropdown-toggle' data-toggle='dropdown'>
          Admin
        </div>
        <div className='dropdown-menu animate slideIn pb-3'>
          <NavLink
            exact
            to='/dodaj'
            className='nav-link ml-4'
            activeClassName='active'
            onClick={onClick}
          >
            Dodaj Poklon
          </NavLink>
          <div onClick={onLogout} className='nav-link ml-4'>
            <i className='fas fa-sign-out-alt'></i>{' '}
            <span className='hide-sm'>Logout</span>
          </div>
        </div>
      </li>
    </ul>
  )

  const guestLinks = (
    <ul className='navbar-nav ml-auto'>
      <li className='nav-item'>
        <NavLink
          exact
          to='/'
          className='nav-link pocetna'
          activeClassName='active'
          onClick={onClick}
        >
          Početna
        </NavLink>
      </li>
      <li className='nav-item dropdown'>
        <div className='nav-link dropdown-toggle' data-toggle='dropdown'>
          Pokloni
        </div>
        <div className='dropdown-menu animate slideIn pb-3'>
          <NavLink
            exact
            to='/pokloni/za-nju-ili-njega'
            className='nav-link ml-4'
            activeClassName='active'
            onClick={onClick}
          >
            Za nju ili njega
          </NavLink>
          <NavLink
            exact
            to='/pokloni/pokloni-za-sve'
            className='nav-link ml-4'
            activeClassName='active'
            onClick={onClick}
          >
            Pokloni za sve
          </NavLink>
          <NavLink
            exact
            to='/pokloni/za-vaspitacice-i-uciteljice'
            className='nav-link ml-4'
            activeClassName='active'
            onClick={onClick}
          >
            Za vaspitačice i učiteljice
          </NavLink>
          <NavLink
            exact
            to='/pokloni/magneti-za-rodjendane'
            className='nav-link ml-4'
            activeClassName='active'
            onClick={onClick}
          >
            Magneti za rođendane
          </NavLink>
          <NavLink
            exact
            to='/pokloni/magneti-za-svadbe'
            className='nav-link ml-4'
            activeClassName='active'
            onClick={onClick}
          >
            Magneti za svadbe
          </NavLink>
          <NavLink
            exact
            to='/pokloni/pozivnice'
            className='nav-link ml-4'
            activeClassName='active'
            onClick={onClick}
          >
            Pozivnice
          </NavLink>

          <NavLink
            exact
            to='/pokloni/repro-materijal'
            className='nav-link ml-4'
            activeClassName='active'
            onClick={onClick}
          >
            Repro materijal
          </NavLink>

          <NavLink
            exact
            to='/pokloni/ostalo'
            className='nav-link ml-4'
            activeClassName='active'
            onClick={onClick}
          >
            Ostalo
          </NavLink>
        </div>
      </li>
      <li className='nav-item dropdown'>
        <div className='nav-link dropdown-toggle' data-toggle='dropdown'>
          Promo
        </div>
        <div className='dropdown-menu animate slideIn pb-3'>
          <a
            href='https://online.fliphtml5.com/zjgjy/cecv/'
            target='_blank'
            className='nav-link ml-4'
          >
            Promotivni Katalog
          </a>
        </div>
      </li>
      <li className='nav-item'>
        <NavLink
          exact
          to='/pokloni/korporativni-pokloni'
          className='nav-link'
          activeClassName='active'
          onClick={onClick}
        >
          Korporativni Pokloni
        </NavLink>
      </li>
      <li className='nav-item'>
        <NavLink
          exact
          to='/kako-poruciti'
          className='nav-link'
          activeClassName='active'
          onClick={onClick}
        >
          Kako Poručiti
        </NavLink>
      </li>
      <li className='nav-item d-flex flex-direction-column'>
        <span>
          <a
            href='https://www.facebook.com/reciseci/'
            target='_blank'
            rel='noopener noreferrer'
            className='nav-link mr-2'
            onClick={onClick}
          >
            <i className='fab fa-facebook-square'></i>
          </a>
        </span>
        <span>
          <a
            href='https://www.instagram.com/reci_seci/?hl=en'
            target='_blank'
            rel='noopener noreferrer'
            className='nav-link'
            onClick={onClick}
          >
            <i className='fab fa-instagram'></i>
          </a>
        </span>
      </li>
    </ul>
  )

  return (
    <Navbar
      expanded={expanded}
      collapseOnSelect
      expand='lg'
      className={
        expanded
          ? 'navbar fixed-top navbar-expand-lg navbar-light expanded'
          : 'navbar fixed-top navbar-expand-lg navbar-light'
      }
      id='main-nav'
    >
      <div className='container'>
        <Navbar.Brand as={Link} to='/' onClick={onClick}>
          <img src={logo} alt='Red Dot Logo' />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls='responsive-navbar-nav'
          onClick={() => setExpanded(expanded ? false : 'expanded')}
        />
        <Navbar.Collapse id='responsive-navbar-nav'>
          {!loading && (
            <Fragment>{isAuthenticated ? authLinks : guestLinks}</Fragment>
          )}
        </Navbar.Collapse>
      </div>
    </Navbar>
  )
}

MainNavbarRev01.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  auth: state.auth,
})

export default connect(mapStateToProps, { logout, setFilters })(MainNavbarRev01)
