import {
  IZMENI_LOADING,
  IZMENI_LOADING_DONE,
  IZMENI_REQUEST,
  IZMENI_FINISHED,
  IZMENI_SUCCESS,
  IZMENI_ERROR
} from './types';
import axios from 'axios';
import { setAlert } from './alert';

// Get Single Poklon
export const getSinglePoklon = id => async dispatch => {
  dispatch({ type: IZMENI_LOADING });
  try {
    const res = await axios.get(`/api/v1/products/${id}`);

    dispatch({
      type: IZMENI_LOADING_DONE,
      payload: res.data.data
    });
  } catch (err) {
    dispatch({
      type: IZMENI_ERROR
    });
    dispatch(setAlert(err.response.data.error, 'danger'));
  }
};

// Get Single Poklon
export const updatePoklon = (id, poklonData) => async dispatch => {
  dispatch({ type: IZMENI_REQUEST });

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };

  const {
    naziv,
    sifra,
    cena,
    dimenzija,
    materijal,
    opis,
    kategorija,
    glavnaSlika,
    slike,
    dostupnost
  } = poklonData;

  // Form data must be created in order to be able to send multipart for data
  const formData = new FormData();
  formData.append('naziv', naziv);
  formData.append('sifra', sifra);
  formData.append('cena', cena);
  formData.append('dimenzija', dimenzija);
  formData.append('materijal', materijal);
  formData.append('opis', opis);
  formData.append('kategorija', kategorija);
  formData.append('dostupnost', dostupnost);
  if (glavnaSlika) {
    formData.append('glavnaSlika', glavnaSlika);
  }

  if (slike) {
    // Append ostale slike
    for (let i = 0; i < slike.length; i++) {
      formData.append('slike', slike[i]);
    }
  }

  try {
    const res = await axios.put(`/api/v1/products/${id}`, formData, config);

    dispatch({
      type: IZMENI_SUCCESS,
      payload: res.data.data
    });
    dispatch({
      type: IZMENI_FINISHED
    });
    dispatch(setAlert('Uspešno ste izmenili poklon', 'info'));
  } catch (err) {
    dispatch({
      type: IZMENI_FINISHED
    });
    dispatch({
      type: IZMENI_ERROR
    });
    dispatch(setAlert(err.response.data.error, 'danger'));
  }
};
