import React, { useRef, useEffect } from 'react'
import { Link as LinkScroll } from 'react-scroll'
import { Link } from 'react-router-dom'
// Images
import testimonial_1 from '../../img/landing/testimonial-1.png'
import testimonial_2 from '../../img/landing/testimonial-2.png'
import testimonial_3 from '../../img/landing/testimonial-3.png'
import laserska_gravura from '../../img/landing/laserska-gravura.png'
import image_decoration from '../../img/landing/image-decoration.svg'
import uv_stampa from '../../img/landing/uv-stampa.png'
import personalizacija from '../../img/landing/personalizacija.png'
import pokloni_za_sve from '../../img/landing/pokloni-za-sve.png'
import za_nju_ili_njega from '../../img/landing/za-nju-i-njega.png'
import za_vaspitacice_i_uciteljice from '../../img/landing/za-vaspitacice-i-uciteljice.png'
import ostalo from '../../img/landing/ostalo.png'
import repro_materijal from '../../img/landing/repro-materijal.png'
import korporativni_pokloni_02 from '../../img/korporativni_pokloni_02.png'
import magneti_za_svadbe from '../../img/landing/magneti-za-svadbe.png'
import magneti_za_rodjendane from '../../img/landing/magneti-za-rodjendane.png'
import pozivnice from '../../img/landing/pozivnice.png'
import promo from '../../img/landing/promo-katalog.jpg'

// GSAP
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

const testimonialImages = [
  {
    image: testimonial_1,
    name: 'Testimonial One',
  },
  {
    image: testimonial_2,
    name: 'Testimonial Two',
  },
  {
    image: testimonial_3,
    name: 'Testimonial Three',
  },
]

const LandingRev01 = () => {
  const testimonialsHeaderRef = useRef(null)
  const revealRefs = useRef([])
  revealRefs.current = []

  const imagesRefs = useRef([])
  imagesRefs.current = []

  const decorationRefs = useRef([])
  decorationRefs.current = []

  const mainHeaderRef = useRef(null)
  const mainSubHeaderRef = useRef(null)
  const mainHeaderButtonsRef = useRef(null)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    // Main Header Animation
    const mainHeaderTimeline = gsap.timeline()
    mainHeaderTimeline
      .from(mainHeaderRef.current, {
        opacity: 0,
        duration: 1,
      })
      .from(
        mainSubHeaderRef.current,
        {
          opacity: 0,
          duration: 1,
        },
        '-=0.2'
      )
      .from(
        mainHeaderButtonsRef.current,
        {
          opacity: 0,
          duration: 0.5,
        },
        '-=0.2'
      )

    // Testimonials Header Animation
    gsap.from(testimonialsHeaderRef.current, {
      scrollTrigger: {
        duration: 1,
        // markers: true,
        trigger: '.testimonials-header-wrapper',
        pin: true, // pin the trigger element while active
        start: 'top top', // when the top of the trigger hits the top of the viewport
        end: '+=700', // end after scrolling 500px beyond the start
        scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
        toggleActions: 'play',
      },
      opacity: 0,
      scale: 0,
      ease: 'back.out(1.7)',
    })

    // Testimonials Images animation
    let tl = gsap.timeline({
      // yes, we can add it to an entire timeline!
      scrollTrigger: {
        duration: 5000,
        // markers: true,
        trigger: '.testimonials-images-wrapper',
        pin: true, // pin the trigger element while active
        start: 'top top', // when the top of the trigger hits the top of the viewport
        end: '+=2000', // end after scrolling 500px beyond the start
        scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
      },
    })

    revealRefs.current.forEach((el, index) => {
      tl.from(el, {
        opacity: 0,
        perspective: 400,
        x: 500,
        rotateY: 90,
      })
        .to(el, {
          perspective: 400,
          x: 0,
          rotateY: 0,
        })
        .to(el, {
          opacity: 0,
        })
    })

    // About images animation
    imagesRefs.current.forEach((el, index) => {
      gsap.from(el, {
        duration: 0.5,
        opacity: 0,
        ease: 'none',
        scrollTrigger: {
          id: `section-${index + 1}`,
          trigger: el,
          start: 'top center',
          toggleActions: 'play none none reverse',
        },
      })
    })

    // Image decoration animation
    decorationRefs.current.forEach((el, index) => {
      gsap.from(el, {
        duration: 1,
        opacity: 0,
        y: -100,
        ease: 'elastic.out(2.5, .8)',
        scrollTrigger: {
          id: `section-${index + 1}`,
          trigger: el,
          start: 'top center',
          toggleActions: 'play none none reverse',
        },
      })
    })
  }, [])

  const addToRefs = (el) => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el)
    }
  }

  const addToImagesRefs = (el) => {
    if (el && !imagesRefs.current.includes(el)) {
      imagesRefs.current.push(el)
    }
  }

  const addToDecorationRefs = (el) => {
    if (el && !decorationRefs.current.includes(el)) {
      decorationRefs.current.push(el)
    }
  }

  return (
    <>
      {/* Header */}
      <header id='landing-header' className='landing-header'>
        <div className='image-overlay'></div>
        <div className='container'>
          <div className='header-wrapper'>
            <h1 ref={mainHeaderRef}>
              <strong>Izaberite</strong> savršen <strong>poklon</strong> za
              svaku priliku
            </h1>
            <h4 ref={mainSubHeaderRef}>
              Izrada personalizovanih poklona i reklamnog materijala
            </h4>
            <div className='button-wrapper' ref={mainHeaderButtonsRef}>
              <LinkScroll
                to='pokloni'
                className='btn btn-dark btn-lg text-white'
                smooth={true}
                // offset={-300}
                duration={500}
              >
                Pokloni
              </LinkScroll>
              <Link
                to='/kako-poruciti'
                className='btn btn-lg btn-outline-primary'
              >
                Kako Poručiti
              </Link>
            </div>
          </div>
        </div>
      </header>
      {/* Testimonials */}
      <section id='testimonials' className='testimonials'>
        <div className='container'>
          <div className='testimonials-header-wrapper'>
            <h1 ref={testimonialsHeaderRef}>
              Budite među brojnim zadovoljnim korisnicima
            </h1>
          </div>

          <div className='testimonials-images-wrapper'>
            {testimonialImages.map(({ image, name }) => (
              <img src={image} alt={name} key={name} ref={addToRefs} />
            ))}
          </div>
        </div>
      </section>

      {/* Pokloni */}
      <section id='pokloni' className='pokloni section'>
        <div className='container'>
          <div className='section-header'>
            <h1>Pokloni</h1>
          </div>
          <div className='pokloni-wrapper'>
            <Link to='/pokloni/pokloni-za-sve' className='pokloni-card'>
              <div className='image-wrapper'>
                <img src={pokloni_za_sve} alt='Pokloni Za Sve' />
                <div className='image-overlay'></div>
              </div>
              <div className='card-btn card-btn-primary'>Izaberi</div>
              <div className='title'>Pokloni Za Sve</div>
            </Link>
            <Link to='/pokloni/za-nju-ili-njega' className='pokloni-card'>
              <div className='image-wrapper'>
                <img src={za_nju_ili_njega} alt='Za Nju Ili Njega' />
                <div className='image-overlay'></div>
              </div>
              <div className='card-btn card-btn-primary'>Izaberi</div>
              <div className='title'>Za Nju ili Njega</div>
            </Link>
            <Link
              to='/pokloni/za-vaspitacice-i-uciteljice'
              className='pokloni-card'
            >
              <div className='image-wrapper'>
                <img
                  src={za_vaspitacice_i_uciteljice}
                  alt='Za Vaspitacice i Uciteljice'
                />
                <div className='image-overlay'></div>
              </div>
              <div className='card-btn card-btn-primary'>Izaberi</div>
              <div className='title'>Za Vaspitačice i Učiteljice</div>
            </Link>
            <Link to='/pokloni/magneti-za-rodjendane' className='pokloni-card'>
              <div className='image-wrapper'>
                <img src={magneti_za_rodjendane} alt='Magneti Za Rođendane' />
                <div className='image-overlay'></div>
              </div>
              <div className='card-btn card-btn-primary'>Izaberi</div>
              <div className='title'>Magneti Za Rođendane</div>
            </Link>
            <Link to='/pokloni/magneti-za-svadbe' className='pokloni-card'>
              <div className='image-wrapper'>
                <img src={magneti_za_svadbe} alt='Magneti Za Svadbe' />
                <div className='image-overlay'></div>
              </div>
              <div className='card-btn card-btn-primary'>Izaberi</div>
              <div className='title'>Magneti Za Svadbe</div>
            </Link>
            <Link to='/pokloni/pozivnice' className='pokloni-card'>
              <div className='image-wrapper'>
                <img src={pozivnice} alt='Pozivnice' />
                <div className='image-overlay'></div>
              </div>
              <div className='card-btn card-btn-primary'>Izaberi</div>
              <div className='title'>Pozivnice</div>
            </Link>
            <Link to='/pokloni/korporativni-pokloni' className='pokloni-card'>
              <div className='image-wrapper'>
                <img src={korporativni_pokloni_02} alt='Korporativni Pokloni' />
                <div className='image-overlay'></div>
              </div>
              <div className='card-btn card-btn-primary'>Izaberi</div>
              <div className='title'>Korporativni Pokloni</div>
            </Link>
            <Link to='/pokloni/repro-materijal' className='pokloni-card'>
              <div className='image-wrapper'>
                <img src={repro_materijal} alt='Repro materijal' />
                <div className='image-overlay'></div>
              </div>
              <div className='card-btn card-btn-primary'>Izaberi</div>
              <div className='title'>Repro materijal</div>
            </Link>
            <Link to='/pokloni/ostalo' className='pokloni-card'>
              <div className='image-wrapper'>
                <img src={ostalo} alt='Ostalo' />
                <div className='image-overlay'></div>
              </div>
              <div className='card-btn card-btn-primary'>Izaberi</div>
              <div className='title'>Ostalo</div>
            </Link>
            <a
              href='https://online.fliphtml5.com/zjgjy/cecv/'
              target='_blank'
              rel='noopener noreferrer'
              className='pokloni-card'
            >
              <div className='image-wrapper'>
                <img src={promo} alt='Promotivni katalog' />
                <div className='image-overlay'></div>
              </div>
              <div className='card-btn card-btn-primary'>Izaberi</div>
              <div className='title'>Promotivni Katalog</div>
            </a>
          </div>
        </div>
      </section>

      {/* About */}
      <section id='about' className='about section'>
        <div className='container'>
          <div className='section-header'>
            <h1>Kako to radimo?</h1>
          </div>
          <div className='about-wrapper'>
            <div className='about-card reverse'>
              <div className='card-image-wrapper'>
                <img
                  src={laserska_gravura}
                  alt='Laserska Gravura'
                  className='image'
                  ref={addToImagesRefs}
                />
                <img
                  src={image_decoration}
                  alt='decoration'
                  className='image-decoration image-decoration-right'
                  ref={addToDecorationRefs}
                />
              </div>
              <div className='card-text-wrapper'>
                <div className='card-heading'>
                  <h2>Laserska Gravura</h2>
                  <i className='far fa-check-circle'></i>
                </div>
                <p className='card-text'>
                  Laserska gravura je tehnika kojom možete ostaviti večni
                  otisak. Udubljenja i prorezi koji su ostavljeni graviranjem se
                  nisu izgubili već su dobili onu finu patinu lepih
                  upotrebljenih predmeta koji čuvaju neke priče zatvorene u
                  sebi. Dugovečnost i istinski kvalitet čine da predmeti i
                  pokloni sa ugraviranim natpisima predstavljaju više od
                  poklona. Predstavljaju početak neke nove priče.
                  <br />
                  Laserska gravura kao tehnika je pogodna za otisak na raznim
                  vrstama predmeta od drveta, kože, pleksiglasa i tome sl.
                  Predmeti koji mogu poslužiti u marketinške svrhe, jer
                  predstavljaju odličan reklamni materijal.
                  <br />
                  Unutar naše firme posedujemo lasersku tehnologiju gravure,
                  tačnije mašine „Gweike“ Garantujemo vrhunski kvalitet i
                  dugotrajnost laserske gravure.
                </p>
              </div>
            </div>
            <div className='about-card'>
              <div className='card-image-wrapper'>
                <img
                  src={uv_stampa}
                  alt='UV Štampa'
                  className='image'
                  ref={addToImagesRefs}
                />
                <img
                  src={image_decoration}
                  alt='decoration'
                  className='image-decoration image-decoration-left'
                  ref={addToDecorationRefs}
                />
              </div>
              <div className='card-text-wrapper'>
                <div className='card-heading'>
                  <h2>UV Štampa Visoke Rezolucije</h2>
                  <i className='far fa-check-circle'></i>
                </div>
                <p className='card-text'>
                  UV digitalna štampa je štampa na svim materijalima u punom
                  koloru. Prateći svetske trendove u digitalnoj štampi naša
                  firma Red Dot je nabavila novi UV printer japanske firme
                  Mimaki radne površine 600x420mm i 150mm visine.
                  <br />
                  Profesionalna digitalna UV štampa znatno ubrzava proces
                  realizacije uštampavanja, jer je vreme pripreme za štampu
                  značajno kraće u odnosu na druge tehnike. Moguće je štampati
                  na različitim materijalima, metalu, plastici, drvetu, koži… UV
                  digitalna štampa omogućava da na širokoj lepezi poklona i
                  promotivnih reklamnih proizvoda prezentujete Vaše poruke,
                  slike kao i Vašu firmu na direktan i efikasan način. Moguće je
                  štampati Vaš logo ili poruku na olovkama, privescima, USB
                  flash memorijama, magnetima, maskama za mobilne telefone,
                  rokovnicima. Nije potrebno štampanje hiljade primeraka da bi
                  se dobila niska cena izrade.
                  <br />
                  Printer pored CMYK sistema boja poseduje i belu boju pa je
                  moguce štampati i na transparentnim materijalima a dobiti
                  savršen otisak.
                </p>
              </div>
            </div>
            <div className='about-card reverse'>
              <div className='card-image-wrapper'>
                <img
                  src={personalizacija}
                  alt='Personalizacija'
                  className='image'
                  ref={addToImagesRefs}
                />
                <img
                  src={image_decoration}
                  alt='decoration'
                  className='image-decoration image-decoration-right'
                  ref={addToDecorationRefs}
                />
              </div>
              <div className='card-text-wrapper'>
                <div className='card-heading'>
                  <h2>Personalizacija poklona</h2>
                  <i className='far fa-check-circle'></i>
                </div>
                <p className='card-text'>
                  Personalizovani poklon – najličniji, specijalno kreiran samo
                  za Vas kako bi darivanje bilo jedinstveno.
                  <br />
                  Personalizacija može biti u različitim formama i oblicima. U
                  mogućnosti smo da personalizujemo gotovo svaki naš do sada
                  urađen proizvod i apsolutno ga prilagodimo Vašim željama kao i
                  da poklon bilo ličnog bilo komercijalnog karaktera bude vezan
                  samo za Vas, Vašu delatnost.
                  <br />
                  Istinska radost naših poklona je rezultat našeg predanog
                  pristupa: stvoriti najlepše poklone za Vas koji nude
                  jednostavnost i lakoću, nadahnuti ih, dati im dušu u želji da
                  naši proizvodi žive i nakon darivanja.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default LandingRev01
