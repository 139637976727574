import {
  GET_POKLONI,
  POKLONI_ERROR,
  SET_LOADING,
  SET_IZDVAJAMO,
  IZDVAJAMO_LOADING_FALSE,
  SET_PAGINATION,
  IZDVAJAMO_ERROR,
  DELETE_POKLON,
  SET_SORT,
  SET_PAGE,
  SET_LIMIT,
  POKLONI_LOADING,
  IZDVAJAMO_LOADING,
  DODAJ_IZMENI_LOADING,
  DODAJ_IZMENI_FINISHED,
  DODAJ_IZMENI_SUCCESS
} from '../actions/types';

const initialState = {
  pokloni: [],
  pokloniLoading: true,
  loading: true,
  izdvajamo: null,
  izdvajamoLoading: true,
  dodajIzmeniLoading: false,
  dodajIzmeniSuccess: false,
  filters: {
    limit: '9',
    page: '1',
    sort: '-createdAt'
  },
  pagination: {},
  error: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_POKLONI:
      return {
        ...state,
        // pokloni: payload.filter(poklon => poklon._id !== state.izdvajamo._id),
        pokloni: payload,
        // loading: false
        pokloniLoading: false
      };
    case POKLONI_ERROR:
      return {
        ...state,
        pokloni: [],
        loading: true,
        izdvajamo: null,
        filters: {
          limit: '9',
          page: '1',
          sort: '-createdAt'
        },
        pagination: {},
        error: {}
      };
    case SET_LOADING:
      return {
        ...state,
        // loading: true
        pokloniLoading: true
      };
    case SET_IZDVAJAMO:
      return {
        ...state,
        izdvajamo: payload,
        izdvajamoLoading: false
        // loading: false
      };
    case IZDVAJAMO_LOADING_FALSE:
      return {
        ...state,
        izdvajamoLoading: false
        // loading: false
      };
    case IZDVAJAMO_ERROR:
      return {
        ...state,
        izdvajamo: null
      };
    case SET_PAGINATION:
      return {
        ...state,
        pagination: payload
      };
    case DELETE_POKLON:
      return {
        ...state,
        izdvajamo: null,
        pokloni: state.pokloni.filter(poklon => poklon._id !== payload)
      };
    case SET_SORT:
      return {
        ...state,
        filters: { ...state.filters, sort: payload, page: '1' }
      };
    case SET_LIMIT:
      return {
        ...state,
        filters: { ...state.filters, limit: payload, page: '1' }
      };
    case SET_PAGE:
      return {
        ...state,
        filters: { ...state.filters, page: payload }
      };
    case POKLONI_LOADING:
      return {
        ...state,
        pokloniLoading: true
      };
    case IZDVAJAMO_LOADING:
      return {
        ...state,
        izdvajamoLoading: true
      };
    case DODAJ_IZMENI_LOADING:
      return {
        ...state,
        dodajIzmeniLoading: true,
        dodajIzmeniSuccess: false
      };
    case DODAJ_IZMENI_FINISHED:
      return {
        ...state,
        dodajIzmeniLoading: false
      };
    case DODAJ_IZMENI_SUCCESS:
      return {
        ...state,
        dodajIzmeniSuccess: true
      };

    default:
      return state;
  }
}
