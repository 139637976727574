import React from 'react'

const Newsletter = () => {
  return (
    <section id='newsletter-section' className='d-flex'>
      <div className='container'>
        <div className='row py-5'>
          <div className='card card-shadow p-5 text-center'>
            <h3 className='text-dark mb-5'>
              Prijavite se za naš newsletter i budite među prvima koji će imati
              informacije o našim proizvodima, ekskluzivnim ponudama i posebnim
              uslovima kupovine u vidu promo kodova sa popustima ili besplatnom
              poštarinom.
            </h3>
            <p className='text-gray lead'>
              Postupak prijave je maksimalno pojednostavljen, sve što treba da
              uradite jeste da popunite sva tri polja u formularu koji se nalazi
              ispod ovog teksta.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Newsletter
