import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link as LinkScroll } from 'react-scroll';
import { sendOrder } from '../../actions/kakoPuruciti';
import PropTypes from 'prop-types';
import SpinnerSmall from '../layout/SpinnerSmall';

const KakoPoruciti = ({
  sendOrder,
  currentSifra,
  formSuccess,
  orderLoading,
}) => {
  const [formData, setFormData] = useState({
    ime: '',
    prezime: '',
    sifra: currentSifra,
    email: '',
    telefon: '',
    adresa: '',
    poruka: '',
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (formSuccess) {
      setFormData({
        ...formData,
        ime: '',
        prezime: '',
        sifra: '',
        email: '',
        telefon: '',
        adresa: '',
        poruka: '',
      });
    }
    // eslint-disable-next-line
  }, [formSuccess]);

  const { ime, prezime, sifra, email, telefon, adresa, poruka } = formData;

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    sendOrder(formData);
  };

  return (
    <section id='kako-poruciti'>
      <div className='container pb-5'>
        <div className='row my-5 py-5'>
          <div className='col card card-shadow text-dark p-5'>
            <h1 className='mb-5'>
              Naše proizvode možete poručiti na sledeći način:
            </h1>
            <h4 className='mb-4'>
              Putem društvenih mreža:{' '}
              <a
                href='https://www.facebook.com/reciseci/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <i
                  className='fab fa-facebook-square mx-2'
                  style={{ color: '#3B5998' }}
                ></i>
              </a>{' '}
              <a
                href='https://www.instagram.com/reci_seci/?hl=en'
                target='_blank'
                rel='noopener noreferrer'
              >
                <i
                  className='fab fa-instagram-square mx-1'
                  style={{ color: '#3F729B' }}
                ></i>
              </a>
            </h4>

            <h4 className='mb-4'>
              <a className='text-decoration-none' href='tel:0612251225'>
                <span className='text-dark'>Putem telefona: </span>
                <span className='text-gray'>061/225-1-225</span>{' '}
                <i
                  className='fab fa-viber mx-2'
                  style={{ color: '#665CAC' }}
                ></i>{' '}
                <i
                  className='fab fa-whatsapp mx-1'
                  style={{ color: '#4FCE5D' }}
                ></i>
              </a>
            </h4>
            <h4 className='mb-4'>
              <a
                className='text-decoration-none'
                href='mailto:reddot.ar@gmail.com'
              >
                <span className='text-dark'>Putem email-a: </span>
                <span className='text-gray'>reddot.ar@gmail.com</span>
              </a>
            </h4>
            <h4 className='mb-5'>
              Putem formulara ispod:{' '}
              <LinkScroll
                className='scroll-button'
                to='formular'
                smooth={true}
                offset={-150}
                duration={500}
              >
                <i className='fas fa-arrow-circle-down mx-2'></i>
              </LinkScroll>
            </h4>

            <p className='text-gray'>
              Prilikom porudžbine bitno je navesti{' '}
              <span className='text-primary'>šifru</span> artikla,{' '}
              <span className='text-primary'>željeni tekst</span> i sl. nakon
              čega ćete dobiti odgovor od strane naših operatera preko kojih će
              se zaključiti porudžbina.
            </p>
            <p className='text-gray'>
              Naše proizvode šaljemo putem kurirskih službi{' '}
              <span className='text-primary'>
                <a
                  href='http://www.aks.rs/'
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{ textDecoration: 'none' }}
                >
                  AKS
                </a>
              </span>
              ,{' '}
              <span className='text-primary'>
                <a
                  href='http://www.postexpress.rs/'
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{ textDecoration: 'none' }}
                >
                  Post Express
                </a>
              </span>
              . Troškove transporta snosi kupac, cena usluga transporta zavisi
              od težine paketa. Zvanične cene troškova transporta možete videti
              na sajtu kurirskih službi.
            </p>
            <p className='text-gray'>
              Snosimo <span className='text-primary'>odgovornost</span> za
              eventualno nastalo oštećenje u transportu.
            </p>
          </div>
        </div>

        {/* Order Form */}
        <form onSubmit={onSubmit}>
          <div className='row my-5 p-5 text-light bg-dark' id='formular'>
            {orderLoading && (
              <div className='formular-overlay'>
                <SpinnerSmall />
              </div>
            )}

            <div className='col-12 text-center mb-3'>
              <h2>NAPRAVITE PORUDŽBINU</h2>
            </div>
            <div className='col-md-4'>
              <div className='form-group'>
                <label htmlFor='ime'>Ime</label>
                <input
                  type='text'
                  className='form-control'
                  id='ime'
                  name='ime'
                  placeholder='Vaše ime'
                  value={ime}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className='col-md-4'>
              <div className='form-group'>
                <label htmlFor='prezime'>Prezime</label>
                <input
                  type='text'
                  className='form-control'
                  id='prezime'
                  name='prezime'
                  placeholder='Vaše prezime'
                  value={prezime}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className='col-md-4'>
              <div className='form-group'>
                <label htmlFor='sifra'>Šifra</label>
                <input
                  type='text'
                  className='form-control'
                  id='sifra'
                  name='sifra'
                  placeholder='Šifra željenog artikla'
                  value={sifra}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className='col-md-4'>
              <div className='form-group'>
                <label htmlFor='email'>Email</label>
                <input
                  type='email'
                  className='form-control'
                  id='email'
                  name='email'
                  placeholder='Vaš email'
                  value={email}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className='col-md-4'>
              <div className='form-group'>
                <label htmlFor='telefon'>Telefon</label>
                <input
                  type='text'
                  className='form-control'
                  id='telefon'
                  name='telefon'
                  placeholder='Kontakt telefon'
                  value={telefon}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className='col-md-4'>
              <div className='form-group'>
                <label htmlFor='adresa'>Adresa</label>
                <input
                  type='text'
                  className='form-control'
                  id='adresa'
                  name='adresa'
                  placeholder='Vaša adresa'
                  value={adresa}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className='form-group col-12'>
              <label htmlFor='poruka'>Poruka</label>
              <textarea
                className='form-control'
                id='poruka'
                name='poruka'
                rows='5'
                value={poruka}
                onChange={onChange}
                placeholder='Pošaljite nam poruku sa vašim željama...'
              ></textarea>
            </div>

            <div className='col-md-4 ml-auto text-right mt-3'>
              <button className='btn btn-primary px-5' type='submit'>
                Pošalji
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

KakoPoruciti.propTypes = {
  sendOrder: PropTypes.func.isRequired,
  currentSifra: PropTypes.string,
};

const mapStateToProps = (state) => ({
  currentSifra: state.kakoPoruciti.sifra,
  formSuccess: state.kakoPoruciti.formSuccess,
  orderLoading: state.kakoPoruciti.orderLoading,
});

export default connect(mapStateToProps, { sendOrder })(KakoPoruciti);
