import axios from 'axios';
import { setAlert } from './alert';
import { NEWSLETTER_REQUEST, NEWSLETTER_FINISHED } from './types';

// Add new Newsletter
export const newsletterSingUp = (ime, prezime, email) => async dispatch => {
  dispatch({ type: NEWSLETTER_REQUEST });
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({ email, ime, prezime });

  try {
    await axios.post('/api/v1/newsletter/signup', body, config);
    dispatch({ type: NEWSLETTER_FINISHED });
    dispatch(setAlert('Uspesno ste se prijavili na naš newsletter', 'info'));
  } catch (err) {
    console.log(err);
    dispatch({ type: NEWSLETTER_FINISHED });
    dispatch(setAlert('Nazalost, prijava nije uspela', 'danger'));
  }
};
