import React, { Fragment, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
// import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import CarouselBasic from '../utils/CarouselBasic';
import { deletePoklon } from '../../actions/pokloni';
import { setSifra } from '../../actions/kakoPuruciti';

const PoklonIzdvajamo = ({
  poklon,
  deletePoklon,
  auth: { isAuthenticated, loading },
  setSifra
}) => {
  const {
    dostupnost,
    slike,
    _id,
    naziv,
    cena,
    materijal,
    dimenzija,
    opis,
    sifra,
    glavnaSlika
  } = poklon;

  // Upozorenje modal state
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // Slike modal state
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const onClick = () => {
    deletePoklon(_id);
    handleClose1();
  };

  const onClickPoruci = () => {
    setSifra(sifra);
  };

  return (
    <Fragment>
      <div className='card poklon-card card-shadow px-5 pt-3 pb-5' id='poklon'>
        <div className='row'>
          <div className='col text-center text-primary mb-3'>
            <h1>IZDVAJAMO IZ PONUDE</h1>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6 mb-3 poklon-slika' onClick={handleShow2}>
            <div className='magnifier'>
              <i className='fas fa-search-plus'></i>
            </div>
            {slike.length > 0 ? (
              <CarouselBasic
                id={_id}
                glavnaSlika={glavnaSlika}
                slike={slike}
                controls={false}
                indicators={false}
                interval={5000}
              />
            ) : (
              <img
                src={`/uploads/${glavnaSlika}`}
                alt=''
                style={{ width: '100%' }}
              />
            )}
          </div>
          <div className='col-md-6 ml-auto text-left d-flex flex-column justify-content-between'>
            <h1 className='text-dark display-3 mb-3'>{naziv}</h1>
            <h3 className='text-dark info-text'>
              Materijal: <span className='text-gray'>{materijal}</span>{' '}
            </h3>
            <h3 className='text-dark info-text'>
              Dimenzija: <span className='text-gray'>{dimenzija} cm</span>{' '}
            </h3>
            <h3 className='text-dark info-text'>
              Šifra: <span className='text-gray'>{sifra}</span>{' '}
            </h3>
            <h3 className='text-dark info-text'>
              Dostupnost:{' '}
              {dostupnost ? (
                <span className='text-gray ml-3'>
                  <i className='fas fa-check-square'></i>
                </span>
              ) : (
                <span className='text-primary ml-3'>
                  <i className='far fa-times-circle'></i>
                </span>
              )}{' '}
            </h3>
            <h3 className='text-dark info-text'>
              Cena od: <span className='text-gray'>{cena} din</span>{' '}
            </h3>
          </div>
        </div>
        <div className='row'>
          <div className='col text-left'>
            <p className='text-dark info-text mb-0 mt-2'>
              {/* Opis:{' '} */}
              <span className='text-gray' style={{ whiteSpace: 'pre-wrap' }}>
                {opis}
              </span>{' '}
            </p>
          </div>
        </div>
        {isAuthenticated ? (
          <div className='row'>
            <div className='col-md-3 ml-auto mt-3 d-flex justify-content-end'>
              <Link
                to={`/izmeni/${_id}`}
                className='btn btn-outline-dark ml-auto mx-3 w-100'
              >
                Izmeni
              </Link>
              <Link
                to='#'
                className='btn btn-outline-primary mx-3 w-100'
                onClick={handleShow1}
              >
                Izbriši
              </Link>
            </div>
          </div>
        ) : (
          <div className='row'>
            <div className='col-md-3 ml-auto mt-3 d-flex justify-content-end'>
              <Link
                to={`/kako-poruciti`}
                className='btn btn-outline-primary ml-auto mx-3 w-100'
                onClick={onClickPoruci}
              >
                Poruči
              </Link>
            </div>
          </div>
        )}

        {/* Upozorenje Modal */}
        <Modal show={show1} onHide={handleClose1} animation={true} centered>
          <Modal.Header closeButton>
            <Modal.Title className='text-dark'>
              <i className='fas fa-exclamation-circle'></i> Upozorenje
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Da li ste sigurni da želite da izbrišete proizvod?
          </Modal.Body>
          <Modal.Footer className='d-flex justify-content-between'>
            <Link to='/' className='btn btn-primary px-5' onClick={onClick}>
              Da
            </Link>
            <button
              className='btn btn-outline-dark px-5'
              onClick={handleClose1}
            >
              Ne
            </button>
          </Modal.Footer>
        </Modal>

        {/* Modal za slike */}
        <Modal
          show={show2}
          onHide={handleClose2}
          animation={true}
          centered
          size='lg'
          id='modal-slike'
        >
          {/* <Modal.Header closeButton></Modal.Header> */}
          {/* <Modal.Body> */}
          {/* <button className='close' type='button' onClick={handleClose2}>
              <span aria-hidden='true'>x</span>
              <span className='sr-only'>Close</span>
            </button> */}
          {slike.length > 0 ? (
            <CarouselBasic
              id={_id}
              glavnaSlika={glavnaSlika}
              slike={slike}
              controls={true}
              indicators={true}
              interval={null}
            />
          ) : (
            <img
              src={`/uploads/${glavnaSlika}`}
              alt=''
              style={{ width: '100%' }}
            />
          )}
          {/* </Modal.Body> */}
        </Modal>
      </div>
    </Fragment>
  );
};

PoklonIzdvajamo.propTypes = {
  poklon: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  deletePoklon: PropTypes.func.isRequired,
  setSifra: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { deletePoklon, setSifra })(
  PoklonIzdvajamo
);
