import React, { Fragment } from 'react';

const NotFound = () => {
  return (
    <Fragment>
      <div className='container' style={{ height: '70vh' }}>
        <div className='row my-5 py-5 text-center'>
          <div className='col my-5 py-5'>
            <h1 className='text-dark'>
              <i className='fas fa-exclamation-triangle'></i> Ups...
            </h1>
            <p className='text-dark'>Žao nam je, ova stranica ne postoji</p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default NotFound;
