import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Accordion, Card, Button } from 'react-bootstrap';
import {
  getPokloni,
  setIzdvajamo,
  setFilters,
  izdvajamoLoadingFalse,
} from '../../actions/pokloni';
import PropTypes from 'prop-types';
import Spinner from '../layout/Spinner';
import PoklonIzdvajamo from './PoklonIzdvajamo';
import PoklonCard from './PoklonCard';

const Pokloni = ({
  match,
  setIzdvajamo,
  setFilters,
  getPokloni,
  pokloni: {
    pokloni,
    izdvajamo,
    filters,
    pagination,
    pokloniLoading,
    izdvajamoLoading,
  },
}) => {
  const history = useHistory();
  const [category, setCategory] = useState(match.params.category);

  useEffect(() => {
    setCategory(match.params.category);
  }, [match.params.category]);

  // Main get pokloni
  useEffect(() => {
    window.scrollTo(0, 0);
    if (!izdvajamo || izdvajamo.kategorija !== category) {
      setIzdvajamo(category);
    }
    getPokloni(category, filters);
    // eslint-disable-next-line
  }, [setIzdvajamo, category]);

  // Filters get pokloni
  useEffect(() => {
    getPokloni(match.params.category, filters);
    // eslint-disable-next-line
  }, [filters]);

  const applyFilters = (e) => {
    window.scrollTo(0, 0);
    const name = e.target.name;
    const value = e.target.value;
    if (name === 'category') {
      const filterObject = { page: '1' };

      setFilters(filterObject);
      history.push(`/pokloni/${value}`);
    } else {
      const filterObject = { [e.target.name]: e.target.value };

      setFilters(filterObject);
    }
  };

  return izdvajamoLoading || pokloniLoading ? (
    <Fragment>
      <Spinner />
    </Fragment>
  ) : pokloni.length === 0 ? (
    <Fragment>
      {/* banner-1 */}
      <section
        style={{ minHeight: '70vh' }}
        className='container'
        id='pokloniBanner-1'
      >
        <div className='section-header margin-top-variant'>
          <h1>Nažalost trenutno nema poklona u izabranoj kategoriji</h1>
        </div>
        <div className='sad-smiley'>
          <p className='text-dark text-center'>
            <i className='far fa-sad-tear'></i>
          </p>
        </div>
      </section>
    </Fragment>
  ) : (
    <Fragment>
      {/* izdvojen Poklon */}
      <section id='izdvojen-poklon' className='my-5 pt-4'>
        <div className='container'>
          {izdvajamo && (
            <PoklonIzdvajamo key={izdvajamo._id} poklon={izdvajamo} />
          )}
        </div>
      </section>

      {/* banner-2 */}
      <section className='container ' id='landingBanner-2'>
        <div className='section-header margin-top-variant'>
          <h1>
            {category === 'za-nju-ili-njega'
              ? 'ZA NJU ILI NJEGA'
              : category === 'pokloni-za-sve'
              ? 'POKLONI ZA SVE'
              : category === 'za-vaspitacice-i-uciteljice'
              ? 'ZA VASPITAČICE I UČITELJICE'
              : category === 'magneti-za-rodjendane'
              ? 'MAGNETI ZA ROĐENDANE'
              : category === 'magneti-za-svadbe'
              ? 'MAGNETI ZA SVADBE'
              : category === 'pozivnice'
              ? 'POZIVNICE'
              : category === 'repro-materijal'
              ? 'REPRO MATERIJAL'
              : category === 'ostalo'
              ? 'OSTALO'
              : 'KORPORATIVNI POKLONI'}
          </h1>
        </div>
      </section>

      {/* Filteri */}
      <section id='filters' className='filters-desktop mb-5 pb-1'>
        <div className='py-4 bg-info text-dark'>
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-md-4'>
                <div className='form-group'>
                  <label htmlFor='sort'>Sortiraj po:</label>
                  <select
                    className='form-control'
                    id='sort'
                    name='sort'
                    onChange={applyFilters}
                    defaultValue={filters.sort}
                  >
                    <option value='+cena'>Ceni rastuće</option>
                    <option value='-cena'>Ceni opadajuće</option>
                    <option value='-createdAt'>Najnoviji</option>
                  </select>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='form-group'>
                  <label htmlFor='limit'>Broj prikazanih:</label>
                  <select
                    className='form-control'
                    id='limit'
                    name='limit'
                    onChange={applyFilters}
                    defaultValue={filters.limit}
                  >
                    <option value='9'>9</option>
                    <option value='21'>21</option>
                    <option value='30'>30</option>
                    <option value='60'>60</option>
                    <option value='1000'>Sve</option>
                  </select>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='form-group'>
                  <label htmlFor='category'>Kategorija:</label>
                  <select
                    className='form-control'
                    id='category'
                    name='category'
                    onChange={applyFilters}
                    defaultValue={category}
                  >
                    <option value='za-nju-ili-njega'>Za nju ili njega</option>
                    <option value='pokloni-za-sve'>Pokloni za sve</option>
                    <option value='za-vaspitacice-i-uciteljice'>
                      Za vaspitačice i učiteljice
                    </option>
                    <option value='magneti-za-rodjendane'>
                      Magneti za rođendane
                    </option>
                    <option value='magneti-za-svadbe'>Magneti za svadbe</option>
                    <option value='pozivnice'>Pozivnice</option>
                    <option value='repro-materijal'>Repro materijal</option>
                    <option value='ostalo'>Ostalo</option>
                    <option value='korporativni-pokloni'>
                      Korporativni pokloni
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id='filters' className='filters-mobile mb-5 pb-1'>
        <div className='bg-info text-dark'>
          <div className='container'>
            <Accordion className='filters-accordion'>
              <Card>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    className='btn-dark'
                    eventKey='0'
                  >
                    Filteri <i className='ml-3 fas fa-sliders-h'></i>
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey='0'>
                  <Card.Body>
                    <div className='row justify-content-center'>
                      <div className='col-md-4'>
                        <div className='form-group'>
                          <label htmlFor='sort'>Sortiraj po:</label>
                          <select
                            className='form-control'
                            id='sort'
                            name='sort'
                            onChange={applyFilters}
                            defaultValue={filters.sort}
                          >
                            <option value='+cena'>Ceni rastuće</option>
                            <option value='-cena'>Ceni opadajuće</option>
                            <option value='-createdAt'>Najnoviji</option>
                          </select>
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className='form-group'>
                          <label htmlFor='limit'>Broj prikazanih:</label>
                          <select
                            className='form-control'
                            id='limit'
                            name='limit'
                            onChange={applyFilters}
                            defaultValue={filters.limit}
                          >
                            <option value='9'>9</option>
                            <option value='21'>21</option>
                            <option value='30'>30</option>
                            <option value='60'>60</option>
                            <option value='1000'>Sve</option>
                          </select>
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className='form-group'>
                          <label htmlFor='category'>Kategorija:</label>
                          <select
                            className='form-control'
                            id='category'
                            name='category'
                            onChange={applyFilters}
                            defaultValue={category}
                          >
                            <option value='za-nju-ili-njega'>
                              Za nju ili njega
                            </option>
                            <option value='pokloni-za-sve'>
                              Pokloni za sve
                            </option>
                            <option value='za-vaspitacice-i-uciteljice'>
                              Za vaspitačice i učiteljice
                            </option>
                            <option value='magneti-za-rodjendane'>
                              Magneti za rođendane
                            </option>
                            <option value='magneti-za-svadbe'>
                              Magneti za svadbe
                            </option>
                            <option value='pozivnice'>Pozivnice</option>
                            <option value='repro-materijal'>
                              Repro materijal
                            </option>
                            <option value='ostalo'>Ostalo</option>
                            <option value='korporativni-pokloni'>
                              Korporativni pokloni
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
        </div>
      </section>

      {/* Gallery */}
      <section id='pokloni-gallery' className='container mb-5'>
        <div className='row'>
          {pokloni.map((poklon, index) => (
            <PoklonCard key={index} poklon={poklon} />
          ))}
        </div>
      </section>

      {/* Pagination */}
      {pagination.lastPage > 1 && (
        <section id='pagination' className='container my-5 py-5'>
          <div className='row'>
            <div className='col d-flex justify-content-center align-items-center'>
              <nav aria-label='Page navigation example'>
                <ul className='pagination'>
                  <li
                    className={
                      !pagination.prev ? 'page-item disabled' : 'page-item'
                    }
                  >
                    <button
                      className='page-link'
                      name='page'
                      value={pagination.prev && pagination.prev.page}
                      onClick={applyFilters}
                    >
                      Prethodna
                    </button>
                  </li>

                  <li
                    className={
                      filters.page === '1' ? 'page-item active' : 'page-item'
                    }
                  >
                    <button
                      className='page-link'
                      name='page'
                      value='1'
                      onClick={applyFilters}
                    >
                      1
                    </button>
                  </li>

                  {pagination.prev &&
                    pagination.prev.page !== 2 &&
                    pagination.prev.page !== 1 && (
                      <li className='page-item disabled'>
                        <button className='page-link'>...</button>
                      </li>
                    )}

                  {pagination.prev && pagination.prev.page !== 1 && (
                    <li
                      className={
                        filters.page === pagination.prev.page
                          ? 'page-item active'
                          : 'page-item'
                      }
                    >
                      <button
                        className='page-link'
                        name='page'
                        value={pagination.prev.page}
                        onClick={applyFilters}
                      >
                        {pagination.prev.page}
                      </button>
                    </li>
                  )}

                  {pagination.prev &&
                    filters.page !== pagination.prev.page.toString() &&
                    pagination.next &&
                    filters.page !== pagination.next.page.toString() && (
                      <li className='page-item active'>
                        <button
                          className='page-link'
                          name='page'
                          value={filters.page}
                          onClick={applyFilters}
                        >
                          {filters.page}
                        </button>
                      </li>
                    )}

                  {pagination.next &&
                    pagination.next.page !== pagination.lastPage && (
                      <li
                        className={
                          filters.page === pagination.next.page
                            ? 'page-item active'
                            : 'page-item'
                        }
                      >
                        <button
                          className='page-link'
                          name='page'
                          value={pagination.next.page}
                          onClick={applyFilters}
                        >
                          {pagination.next.page}
                        </button>
                      </li>
                    )}

                  {pagination.next &&
                    pagination.next.page !== pagination.lastPage - 1 &&
                    pagination.next.page !== pagination.lastPage && (
                      <li className='page-item disabled'>
                        <button className='page-link'>...</button>
                      </li>
                    )}

                  <li
                    className={
                      filters.page === pagination.lastPage.toString()
                        ? 'page-item active'
                        : 'page-item'
                    }
                  >
                    <button
                      className='page-link'
                      name='page'
                      value={pagination.lastPage}
                      onClick={applyFilters}
                    >
                      {pagination.lastPage}
                    </button>
                  </li>

                  <li
                    className={
                      !pagination.next ? 'page-item disabled' : 'page-item'
                    }
                  >
                    <button
                      className='page-link'
                      name='page'
                      value={pagination.next ? pagination.next.page : ''}
                      onClick={applyFilters}
                    >
                      Sledeća
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </section>
      )}
    </Fragment>
  );
};

Pokloni.propTypes = {
  getPokloni: PropTypes.func.isRequired,
  setIzdvajamo: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  izdvajamoLoadingFalse: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  pokloni: state.pokloni,
});

export default connect(mapStateToProps, {
  getPokloni,
  setIzdvajamo,
  setFilters,
  izdvajamoLoadingFalse,
})(Pokloni);
