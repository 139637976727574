import React, { Fragment, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login } from '../../actions/auth';

const Login = ({ login, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const { email, password } = formData;

  const onChange = async e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    login(email, password);
  };

  // Redirect if Logged In
  if (isAuthenticated) {
    return <Redirect to='/' />;
  }

  return (
    <Fragment>
      <div className='container py-5 my-5' id='login'>
        <div className='row my-5 py-5 login-border'>
          <div className='col py-5'>
            <p className='text-dark display-3 mb-4'>
              <i className='fas fa-user-tie'></i> Log in
            </p>
            <form className='form' onSubmit={e => onSubmit(e)}>
              <div className='form-group mb-4'>
                <input
                  className='px-2 py-1'
                  type='email'
                  placeholder='Email'
                  name='email'
                  value={email}
                  onChange={e => onChange(e)}
                  required
                />
              </div>
              <div className='form-group mb-4'>
                <input
                  className='px-2 py-1'
                  type='password'
                  placeholder='Šifra'
                  name='password'
                  minLength='6'
                  value={password}
                  onChange={e => onChange(e)}
                  required
                />
              </div>

              <input
                type='submit'
                className='btn btn-outline-dark'
                value='Uloguj se'
              />
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { login })(Login);
