import {
  // SET_LOADING,
  SET_CURRENT_POKLON,
  SET_SIMILAR,
  OPSIRNIJE_ERROR,
  OPSIRNIJE_LOADING,
  SIMILAR_LOADING
} from './types';
import axios from 'axios';
// import { setAlert } from './alert';

// Get Current Poklon
export const getCurrentPoklon = id => async dispatch => {
  dispatch({ type: OPSIRNIJE_LOADING });
  dispatch({ type: SIMILAR_LOADING });
  try {
    const res = await axios.get(`/api/v1/products/${id}`);

    dispatch({
      type: SET_CURRENT_POKLON,
      payload: res.data.data
    });
  } catch (err) {
    dispatch({
      type: OPSIRNIJE_ERROR
    });
  }
};

// Get Similar Poklons
export const getSimilar = category => async dispatch => {
  dispatch({ type: SIMILAR_LOADING });
  try {
    const res = await axios.get(`/api/v1/products/similar/${category}`);
    let similar = res.data.data;

    dispatch({
      type: SET_SIMILAR,
      payload: similar
    });
  } catch (err) {
    dispatch({
      type: OPSIRNIJE_ERROR
    });
  }
};
