import React from 'react'
import { Route, Switch } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'

// Components
import Login from '../auth/Login'
// import Landing from '../landing/Landing';
import Landing from '../landing/LandingRev01'
import KakoPoruciti from '../kako-poruciti/KakoPoruciti'
import Izmeni from '../izmeni/Izmeni'
import Dodaj from '../dodaj/Dodaj'
import Pokloni from '../pokloni/Pokloni'
import Opsirnije from '../pokloni/Opsirnije'
import Newsletter from '../newsletter/Newsletter'
import NotFound from '../layout/NotFound'

const Routes = () => {
  return (
    <Switch>
      <Route exact path='/' component={Landing} />
      <Route exact path='/kako-poruciti' component={KakoPoruciti} />
      <Route exact path='/pokloni/:category' component={Pokloni} />
      <Route exact path='/login' component={Login} />
      <Route exact path='/poklon/:id' component={Opsirnije} />
      <Route exact path='/newsletter' component={Newsletter} />
      <PrivateRoute Route exact path='/dodaj' component={Dodaj} />
      <PrivateRoute Route exact path='/izmeni/:id' component={Izmeni} />
      <Route component={NotFound} />
    </Switch>
  )
}

export default Routes
