import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const SlicniProizvod = ({ poklon }) => {
  const { glavnaSlika, _id } = poklon;

  return (
    <div className='col-lg-2 col-md-4 col-6 p-3 slicni-proizvod'>
      <div className='overlay-container'>
        <div className='card'>
          <img
            className='card-img-top'
            src={`/uploads/${glavnaSlika}`}
            alt='showcase'
          />
        </div>
        <Link to={`/poklon/${_id}`}>
          <div className='card card-overlay p-3'>
            <div className='div btn btn-outline-dark'>Pogledaj</div>
          </div>
        </Link>
      </div>
    </div>
  );
};

SlicniProizvod.propTypes = {
  poklon: PropTypes.object.isRequired,
};

export default SlicniProizvod;
