import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'

const InfoModal = () => {
  const [show, setShow] = useState(true)

  const handleClose = () => setShow(false)

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Nova E-prodavnica</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Dragi naši kupci, sa zadovoljstvom Vas obaveštavamo da će uskoro sa
            radom početi prva, savremena E-prodavnica personalizovanih poklona
            na našim prostorima!
          </p>
          <p class='text-gray'>
            Ukoliko želite da budete obavešteni o startovanju prodavnice,
            prijavite se na naš newsletter.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-outline-dark' onClick={handleClose}>
            Zatvori
          </button>
          <Link
            onClick={handleClose}
            to={`/newsletter`}
            className='btn btn-dark'
          >
            Prijavi se
          </Link>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default InfoModal
