import {
  SET_SIFRA,
  FORM_SUCCESS,
  ORDER_LOADING,
  ORDER_FINISHED
} from '../actions/types';
import axios from 'axios';
import { setAlert } from './alert';

// Add Poklon
export const sendOrder = formData => async dispatch => {
  dispatch({ type: ORDER_LOADING });
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    await axios.post('/api/v1/order', formData, config);

    dispatch(setAlert('Uspešno ste poslali porudžbinu', 'info'));
    dispatch({ type: FORM_SUCCESS });
    dispatch({ type: ORDER_FINISHED });
  } catch (err) {
    dispatch(setAlert(err.response.data.error, 'danger'));
    dispatch({ type: ORDER_FINISHED });
  }
};

// Set Sifra
export const setSifra = sifra => async dispatch => {
  dispatch({ type: SET_SIFRA, payload: sifra });
};
