import {
  GET_POKLONI,
  POKLONI_ERROR,
  SET_IZDVAJAMO,
  IZDVAJAMO_LOADING_FALSE,
  SET_PAGINATION,
  IZDVAJAMO_ERROR,
  DELETE_POKLON,
  SET_LIMIT,
  SET_PAGE,
  SET_SORT,
  POKLONI_LOADING,
  IZDVAJAMO_LOADING,
  DODAJ_IZMENI_LOADING,
  DODAJ_IZMENI_FINISHED,
  DODAJ_IZMENI_SUCCESS
} from './types';
import axios from 'axios';
import { setAlert } from './alert';

// Get Pokloni
export const getPokloni = (category, filters) => async dispatch => {
  dispatch({ type: POKLONI_LOADING });
  try {
    const { limit, page, sort } = filters;

    const res = await axios.get(
      `/api/v1/products/?kategorija=${category}&limit=${limit}&page=${page}&sort=${sort}`
    );

    dispatch({
      type: GET_POKLONI,
      payload: res.data.data
    });

    dispatch({ type: SET_PAGINATION, payload: res.data.pagination });
  } catch (err) {
    dispatch({
      type: POKLONI_ERROR
    });
  }
};

// Get Pokloni Izdvajamo
export const setIzdvajamo = category => async dispatch => {
  dispatch({ type: IZDVAJAMO_LOADING });
  const random_item = items => {
    return items[Math.floor(Math.random() * items.length)];
  };

  try {
    const res = await axios.get(`/api/v1/products/?kategorija=${category}`);

    const izdvajamo = random_item(res.data.data);

    dispatch({
      type: SET_IZDVAJAMO,
      payload: izdvajamo
    });
  } catch (err) {
    dispatch({
      type: IZDVAJAMO_ERROR
    });
  }
};

// Get Pokloni Izdvajamo
export const izdvajamoLoadingFalse = () => async dispatch => {
  dispatch({
    type: IZDVAJAMO_LOADING_FALSE
  });
};

// Delete Poklon
export const deletePoklon = id => async dispatch => {
  try {
    await axios.delete(`/api/v1/products/${id}`);

    dispatch({ type: DELETE_POKLON, payload: id });

    dispatch(setAlert('Uspešno ste izbrisali poklon', 'info'));
  } catch (err) {
    dispatch(
      setAlert('Došlo je do greške prilikom brisanja poklona', 'danger')
    );
  }
};

// Add Poklon
export const addPoklon = poklonData => async dispatch => {
  dispatch({ type: DODAJ_IZMENI_LOADING });
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };

  const {
    naziv,
    sifra,
    cena,
    dimenzija,
    materijal,
    opis,
    kategorija,
    glavnaSlika,
    slike,
    dostupnost
  } = poklonData;

  // Form data must be created in order to be able to send multipart for data
  const formData = new FormData();
  formData.append('naziv', naziv);
  formData.append('sifra', sifra);
  formData.append('cena', cena);
  formData.append('dimenzija', dimenzija);
  formData.append('materijal', materijal);
  formData.append('opis', opis);
  formData.append('kategorija', kategorija);
  formData.append('dostupnost', dostupnost);
  formData.append('glavnaSlika', glavnaSlika);
  // Append ostale slike
  for (let i = 0; i < slike.length; i++) {
    formData.append('slike', slike[i]);
  }

  try {
    await axios.post('/api/v1/products', formData, config);

    dispatch(setAlert('Uspešno ste dodali novi poklon', 'info'));
    dispatch({ type: DODAJ_IZMENI_FINISHED });
    dispatch({ type: DODAJ_IZMENI_SUCCESS });
  } catch (err) {
    dispatch(setAlert(err.response.data.error, 'danger'));
    dispatch({ type: DODAJ_IZMENI_FINISHED });
  }
};

// Set Filters
export const setFilters = filter => async dispatch => {
  if (filter.sort) {
    dispatch({ type: SET_SORT, payload: filter.sort });
  }

  if (filter.limit) {
    dispatch({ type: SET_LIMIT, payload: filter.limit });
  }

  if (filter.page) {
    dispatch({ type: SET_PAGE, payload: filter.page });
  }
};
