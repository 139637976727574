import React, { Fragment, useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import './App.css'

// Redux
import { Provider } from 'react-redux'
import store from './store'
import { loadUser } from './actions/auth'
import setAuthToken from './utils/setAuthToken'

// Components
// import MainNavbar from './components/layout/MainNavbar';
import MainNavbar from './components/layout/MainNavbarRev01'
import Newsletter from './components/layout/Newsletter'
import Footer from './components/layout/Footer'
import Alert from './components/layout/Alert'
import Routes from './components/routing/Routes'
import InfoModal from './components/info-modal/infoModal'

// Set Auth Token
if (localStorage.token) {
  setAuthToken(localStorage.token)
}

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser())
  }, [])

  return (
    <Provider store={store}>
      <Router>
        <Fragment>
          <MainNavbar />
          <Alert />
          <InfoModal />
          <Switch>
            <Route component={Routes} />
          </Switch>
          <Newsletter />
          <Footer />
        </Fragment>
      </Router>
    </Provider>
  )
}

export default App
