export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED';
export const GET_POKLONI = 'GET_POKLONI';
export const POKLONI_ERROR = 'POKLONI_ERROR';
export const SET_LOADING = 'SET_LOADING';
export const SET_IZDVAJAMO = 'SET_IZDVAJAMO';
export const IZDVAJAMO_LOADING_FALSE = 'IZDVAJAMO_LOADING_FALSE';
export const SET_PAGINATION = 'SET_PAGINATION';
export const IZDVAJAMO_ERROR = 'IZDVAJAMO_ERROR';
export const DELETE_POKLON = 'DELETE_POKLON';
export const ADD_POKLON = 'ADD_POKLON';
export const SET_STATE_CATEGORY = 'SET_STATE_CATEGORY';
export const SET_LIMIT = 'SET_LIMIT';
export const SET_PAGE = 'SET_PAGE';
export const SET_SORT = 'SET_SORT';
export const SET_SIFRA = 'SET_SIFRA';
export const SET_CURRENT_POKLON = 'SET_CURRENT_POKLON';
export const OPSIRNIJE_ERROR = 'OPSIRNIJE_ERROR';
export const SET_SIMILAR = 'SET_SIMILAR';
export const POKLONI_LOADING = 'POKLONI_LOADING';
export const IZDVAJAMO_LOADING = 'IZDVAJAMO_LOADING';
export const OPSIRNIJE_LOADING = 'OPSIRNIJE_LOADING';
export const SIMILAR_LOADING = 'SIMILAR_LOADING';
export const FORM_SUCCESS = 'FORM_SUCCESS';
export const ORDER_LOADING = 'ORDER_LOADING';
export const ORDER_FINISHED = 'ORDER_FINISHED';
export const DODAJ_IZMENI_LOADING = 'DODAJ_IZMENI_LOADING';
export const DODAJ_IZMENI_FINISHED = 'DODAJ_IZMENI_FINISHED';
export const DODAJ_IZMENI_SUCCESS = 'DODAJ_IZMENI_SUCCESS';
export const IZMENI_LOADING = 'IZMENI_LOADING';
export const IZMENI_LOADING_DONE = 'IZMENI_LOADING_DONE';
export const IZMENI_REQUEST = 'IZMENI_REQUEST';
export const IZMENI_SUCCESS = 'IZMENI_SUCCESS';
export const IZMENI_FINISHED = 'IZMENI_FINISHED';
export const IZMENI_ERROR = 'IZMENI_ERROR';
export const NEWSLETTER_REQUEST = 'NEWSLETTER_REQUEST';
export const NEWSLETTER_FINISHED = 'NEWSLETTER_FINISHED';
