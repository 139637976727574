import React, { Fragment, useEffect } from 'react';
import PokloniForm from '../utils/PokloniForm';

const Dodaj = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
      <div className='container my-5 py-5' id='dodaj'>
        <div className='row'>
          <div className='col'>
            <PokloniForm />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Dodaj;
